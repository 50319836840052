<template>
  <div>
    <div class="divcenter">
      <el-upload
          ref="upload"
          class="upload-demo"
          action="https://www.cr12cz.cn/czscsms/up/upload"
          :auto-upload="false"
          :data="dataup"
          :on-change="imgchange"
          :on-remove="handleRemove"
          :multiple="false"
          :on-success="upsuccess"
          :limit="1"
      >
        <template #trigger>
          <el-button type="primary">请选择技术联系单文件</el-button>
        </template>

        <el-tag style="margin-left: 20px" size="" type="info">工区</el-tag>
        <el-select style="margin-left: 5px;margin-bottom: 20px" v-model="oper" placeholder="请选择标段">
          <el-option
              v-for="item in bdsts"
              :key="item.bdst"
              :label="item.bdstname"
              :value="item.bdst"
          >
          </el-option>
        </el-select>
        <div>
          <el-button
              style="width: 480px"
              type="success"
              @click="submitUpload"
          >上传
          </el-button>
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import { ElMessage } from 'element-plus'
export default {
  inject: ['reload'],
  name: "connect",
  data() {
    return {
      fileList: [],
      dataup: {
        file: '',
        userid: ''
      },
      oper: '',
      bdsts: []
    }
  },
  created() {
    this.axios.get('/other/exzx', (response) => {
      this.bdsts = response.obj;
      this.bdsts.push({bdst: 2,bdstname: '2标'});
      this.bdsts.push({bdst: 5,bdstname: '5标'});
    });
  },
  computed: {
    ...mapGetters(['GET_USERID'])
  },
  methods: {
    submitUpload() {
      if (this.oper === ''){
        ElMessage.error("请选择要对应的工区")
      }else {
        this.dataup.file = this.fileList[0].raw;
        this.dataup.userid = this.GET_USERID;
        this.$refs.upload.submit();
      }
    },
    imgchange(file) {
      this.fileList.push(file);
    },
    handleRemove(file) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
          this.fileList.splice(i, 1)
        }
      }
    },
    upsuccess(response) {
      if (response.obj) {
        let arr = response.obj.split("/");
        let filename = arr[arr.length - 1];
        this.axios.post('/fileurl/save', (response) => {
          if (response.obj){
            this.reload();
            ElMessage({
              message: '提交成功！',
              type: 'success',
            })
          }
        },{
          filetype: 1,
          fileurl: response.obj,
          bdst: this.oper,
          filename: filename
        })
      }
    }
  }
}
</script>

<style scoped>
.divcenter {
  width: 500px;
  height: 500px;
  padding-top: 200px;
  margin: auto;
}
</style>